import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import ComplaintsComplianceNav from '../../../components/SubPageNav/ComplaintsComlianceNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'

const BuilderCompliancePage = () => {
  const intl = useIntl()
  return (
    <Layout
      view='builder'
      breadcrumb={{
        text: <FormattedMessage id='builderNav.dashboard' />,
        path: '/builder-vendor/dashboard/'
      }}
      header={<FormattedMessage id='builderNav.complaints3' />}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'builderNav.complaints' })}
      />
      <Container>
        <Row>
          <Col lg={3} md={3}>
            <ComplaintsComplianceNav activePath='/builder-vendor/complaints-compliance-enforcement/compliance-enforcement/' />
          </Col>

          <Col>
            <Row className='pageRow' style={{ marginTop: `0` }}>
              <Col>
                <h2>
                  <FormattedMessage id='builderComplaints3.headerA' />
                </h2>
                <p>
                  <FormattedMessage id='builderComplaints3.contentAi' />
                </p>
                <ul>
                  <li>
                    <FormattedMessage id='builderComplaints3.list01A' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list01B' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list01C' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list01D' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list01E' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list01F' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list01G' />
                  </li>
                </ul>
                <p>
                  <FormattedMessage id='builderComplaints3.contentAii' />
                </p>
              </Col>
            </Row>

            <Row className='pageRow'>
              <Col>
                <h2>
                  <FormattedMessage id='builderComplaints3.headerB' />{' '}
                </h2>
                <p>
                  <FormattedMessage id='builderComplaints3.contentBi' />
                </p>
                <p>
                  <FormattedMessage id='builderComplaints3.contentBii' />
                </p>
                <ul>
                  <li>
                    <FormattedMessage id='builderComplaints3.list02A' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list02B' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list02C' />
                  </li>
                </ul>
                <p>
                  <FormattedMessage id='builderComplaints3.contentBiii' />
                </p>
                <p>
                  <FormattedMessage id='builderComplaints3.contentBiv' />
                </p>
              </Col>
            </Row>

            <Row className='pageRow'>
              <Col>
                <h2>
                  <FormattedMessage id='builderComplaints3.headerC' />
                </h2>
                <p>
                  <FormattedMessage id='builderComplaints3.contentC' />
                </p>
              </Col>
            </Row>

            <Row className='pageRow'>
              <Col>
                <h2>
                  <FormattedMessage id='builderComplaints3.headerD' />
                </h2>
                <p>
                  <FormattedMessage id='builderComplaints3.contentDi' />
                </p>
                <ul>
                  <li>
                    <FormattedMessage id='builderComplaints3.list03A' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list03B' />
                  </li>
                  <li>
                    <FormattedMessage id='builderComplaints3.list03C' />
                  </li>
                </ul>
                <p>
                  <FormattedMessage id='builderComplaints3.contentDii' />
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='builderNav.complaints2' />,
                path:
                  '/builder-vendor/complaints-compliance-enforcement/licence-appeal-tribunal/'
              }}
              nextLink={{
                text: <FormattedMessage id='daCommittee.headerB' />,
                path:
                  '/builder-vendor/complaints-compliance-enforcement/code-of-ethics/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BuilderCompliancePage
